@use '@/styles/utils/mixins.scss' as *;

.jobCardsWrapper {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.25rem;
  @include tab() {
    margin-top: 5.125rem;
  }

  .jobCardsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-top: 5rem;

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 -1.875rem;
      margin-top: 3.75rem;
    }

    .sectionHeadingContainer {
      display: flex;
      gap: 2.5rem;
      justify-content: space-between;
      @include tab() {
        flex-direction: column;
      }
    }
  }

  .jobCardsAction {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    @include tab() {
      flex-direction: column;
      gap: 1.25rem;
    }
  }

  .noJobCard {
    width: 100%;
    grid-column: span 2;
    @include tab() {
      grid-column: span 1;
    }
  }
}
